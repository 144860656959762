import React from 'react'
import { accessMap, container, map } from './Access.module.scss'
import { Title } from '../../ui/Title'

const nearestStation = [
	'JR総武線本線　馬喰町駅 C1出口:徒歩2分',
	'都営浅草線 東日本橋駅 B1出口：徒歩4分',
	'都営新宿線　　 馬喰横山駅 A1出口：徒歩6分',
	'ＪＲ中央線 浅草橋駅 東口:徒歩7分',
]

export const Access = () => {
	return (
		<div className={container} id="access-position">
			<Title title="アクセス" titleSub="ACCESS" horizontal={false} className="flex-col items-center" />
			<div className={map}>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1620.1735129402218!2d139.78367305816118!3d35.693077023415874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188f56b771b23b%3A0xe74a93ad6e1c322c!2sLIT%20HIGASHI-NIHOMBASHI!5e0!3m2!1sja!2sjp!4v1658287858549!5m2!1sja!2sjp"
					className={accessMap}
					allowFullScreen={true}
					title="LisaTechnologies株式会社"
					loading="lazy"
				/>
			</div>
			<div className="mt-7 text-sm">
				<h4 className="font-bold">Lisa Technologies 株式会社</h4>
				<p className="mt-2 leading-6 lg:leading-5">
					〒103-0004 東京都中央区東日本橋2丁目24−9 LIT 4F
				</p>
			</div>
			<div className="mt-7 text-sm">
				<h4 className="font-bold">交通のご案内</h4>
				{nearestStation.map((station) => {
					return (
						<p key={station} className="mt-2.5">
							{station}
						</p>
					)
				})}
			</div>
		</div>
	)
}
