import React from 'react'
import classnames from 'classnames'
import {
	chiefContainer,
	container,
	favFood,
	memberBack,
	memberContaienr,
	memberFav,
	memberKusumoto,
	memberMiyazaki,
	memberNishimura,
	memberTakase,
	twitterImg,
} from './Member.module.scss'
import { Title } from '../../ui/Title'

const chiefMember = [
	{
		name: '西村 龍紀',
		role: 'CEO',
		bg: memberNishimura,
		description:
			'2011年 ChProductionを創業。ライブ配信サービスを展開するが失敗し、約1,000万の借金を抱えたが3年で返済。 2017年にLisa Technologies株式会社を創業。MEOの販売を始め、自社サービス『TOGO』『ストアーズスコア』を展開してきた。',
		twitter: 'https://twitter.com/nishimura_25',
		favorite: '味噌煮込みうどん',
	},
]

const member = [
	{ name: '高瀬 亮', role: 'COO', bg: memberTakase, favorite: 'スープカレー' },
	{ name: '楠本 明花梨', role: 'サーバーサイドエンジニア', bg: memberKusumoto, favorite: '梨' },
	{ name: '宮崎 寛', role: 'フロントエンジニア', bg: memberMiyazaki, favorite: 'お寿司' },
]

export const Member = () => {
	return (
		<div className={container} id="member-position">
			<Title title="チーム紹介" titleSub="MEMBER" horizontal={false} className="flex-col items-center" />
			<div className="mt-12">
				<div className="flex flex-col lg:flex-row justify-center">
					{chiefMember.map((member) => {
						return (
							<div key={member.name} className={chiefContainer}>
								<div className={classnames(memberBack, 'relative mx-auto rounded-circle')}>
									<p className={classnames(memberFav, 'text-xs text-secondary')}>好物</p>
									<p className={classnames(favFood, 'text-sm text-secondary')}>{member.favorite}</p>
									<div
										className={classnames(
											member.bg,
											'mx-auto w-full h-full bg-center bg-no-repeat bg-contain rounded-circle'
										)}
									/>
								</div>
								<h3 className="mt-5 text-lg font-bold text-center">{member.name}</h3>
								<small className="block mt-2 text-xs text-center">{member.role}</small>
								<p className="mt-6 text-sm leading-6">{member.description}</p>
								{member.twitter ? (
									<a
										href={member.twitter}
										target="_blank"
										rel="noopener noreferrer"
										className="inline-block mt-6 hover:opacity-70 duration-300"
									>
										<div className={classnames(twitterImg, 'bg-center bg-no-repeat bg-cover')} />
									</a>
								) : null}
							</div>
						)
					})}
				</div>
				<div className="flex flex-wrap justify-between lg:justify-evenly mt-24">
					{member.map((member) => {
						return (
							<div key={member.name} className={memberContaienr}>
								<div className={classnames(memberBack, 'relative rounded-circle')}>
									<p className={classnames(memberFav, 'text-xs text-secondary')}>好物</p>
									<p className={classnames(favFood, 'text-sm text-secondary')}>{member.favorite}</p>
									<div
										className={classnames(
											member.bg,
											'mx-auto w-full h-full bg-center bg-no-repeat bg-contain rounded-circle'
										)}
									/>
								</div>
								<h3 className="mt-5 text-lg font-bold text-center">{member.name}</h3>
								<small className="block mt-2 text-xs text-center">{member.role}</small>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
