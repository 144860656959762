// extracted by mini-css-extract-plugin
export var container = "Member-module--container--qG2jo";
export var chiefContainer = "Member-module--chiefContainer--o8N1Z";
export var memberContaienr = "Member-module--memberContaienr--4AckH";
export var memberBack = "Member-module--memberBack--cVYzC";
export var favFoodIn = "Member-module--fav-food-in--fwpyW";
export var memberImg = "Member-module--memberImg--9VT3c";
export var memberTakase = "Member-module--memberTakase--NQbgt";
export var memberMiyazaki = "Member-module--memberMiyazaki--q5PPS";
export var memberKusumoto = "Member-module--memberKusumoto--adY5z";
export var memberNishimura = "Member-module--memberNishimura--nwiCF";
export var memberFav = "Member-module--memberFav--U8+hq";
export var favFood = "Member-module--favFood--5NWv+";
export var twitterImg = "Member-module--twitterImg--wzp+q";