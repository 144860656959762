import * as React from 'react'
import { Layout } from '../components/common/Layout'
import { SEO } from '../components/common/SEO'
import { Company } from '../components/domains/company'

const CompanyPage = () => {
	return (
		<Layout location={[{ name: '会社情報', path: '/company' }]}>
			<SEO title="会社情報" description="" />
			<Company />
		</Layout>
	)
}

export default CompanyPage
