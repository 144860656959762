import React, { useCallback } from 'react'
import { ArrowButtom } from '../../ui/Icon'
import { Title } from '../../ui/Title'

export const CompanyHeader = () => {
	const handleScrollPosition = useCallback((type: 'info' | 'access' | 'member') => {
		const position = document.getElementById(`${type}-position`).offsetTop

		window.scrollTo({
			top: position,
			behavior: 'smooth',
		})
	}, [])

	return (
		<div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
			<Title title="会社情報" titleSub="COMPANY" tag="h1" horizontal={false} className="flex-col" />
			<div className="mt-12 lg:mt-0">
				<button
					className="inline-flex items-center text-sm text-gray-darken hover:opacity-70 duration-300 cursor-pointer"
					onClick={() => handleScrollPosition('info')}
				>
					会社概要
					<span className="ml-2 fill-secondary">
						<ArrowButtom />
					</span>
				</button>
				<button
					className="inline-flex items-center ml-7 lg:ml-12 text-sm text-gray-darken hover:opacity-70 duration-300 cursor-pointer"
					onClick={() => handleScrollPosition('access')}
				>
					アクセス
					<span className="ml-2 fill-secondary">
						<ArrowButtom />
					</span>
				</button>
				<button
					className="inline-flex items-center ml-7 lg:ml-12 text-sm text-gray-darken hover:opacity-70 duration-300 cursor-pointer"
					onClick={() => handleScrollPosition('member')}
				>
					チーム紹介
					<span className="ml-2 fill-secondary">
						<ArrowButtom />
					</span>
				</button>
			</div>
		</div>
	)
}
