import React from 'react'
import { Access } from './Access'
import { CompanyHeader } from './CompanyHeader'
import { Info } from './Info'
import { Member } from './Member'
import { container, recruitBurger } from './Company.module.scss'
import { RecruitButton } from './RecruitButton'

export const Company = () => {
	return (
		<div className={container}>
			<CompanyHeader />
			<Info />
			<Access />
			<Member />
			<a
				href="https://www.notion.so/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
				target="_blank"
				rel="noopener noreferrer"
				className="absolute right-8 lg:right-0 -bottom-24 z-10"
			>
				<RecruitButton className={recruitBurger} />
			</a>
		</div>
	)
}
